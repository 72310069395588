import classNames from "classnames";
import React from "react";
import Link from "../components/link";
import article from "../styles/article.module.css";

const tabs = {
  "Our Projects": [
    {
      name: "findcovidtesting.com",
      url: "/our-projects/findcovidtesting",
      id: "findcovidtesting"
    },
    {
      name: "open-data",
      url: "/our-projects/open-data",
      id: "open-data"
    }
  ]
};

export default function ArticleTabs(props) {
  const { section, subsection } = props;
  const tabGroup = tabs[section];

  return tabGroup ? (
    <div className={article.tabs}>
      {tabGroup.map(({ name, url, id }) => {
        const selected = id === subsection;

        return (
          <Link
            to={url}
            className={classNames(article.tab, selected && article.selectedTab)}
          >
            {name}
          </Link>
        );
      })}
    </div>
  ) : null;
}
