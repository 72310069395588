import classNames from "classnames";
import React from "react";
import { graphql } from "gatsby";

import ArticleTabs from "../components/articleTabs";
import Layout from "../components/layout";
import Link from "../components/link";
import Heading from "../components/heading";
import Section from "../components/section";
import SEO from "../components/seo";
import Subheading from "../components/subheading";
import article from "../styles/article.module.css";
import components from "../styles/components.module.css";

const relatedArticles = {
  findcovidtesting: [
    {
      name: "Intro",
      url: "/our-projects/findcovidtesting",
      id: "findcovidtesting-intro"
    },
    {
      name: "FAQ",
      url: "/our-projects/findcovidtesting/faq",
      id: "findcovidtesting-faq"
    }
  ],
  "open-data": [
    {
      name: "Intro",
      url: "/our-projects/open-data",
      id: "open-data"
    },
    {
      name: "FAQ",
      url: "/our-projects/open-data/faq",
      id: "open-data-faq"
    }
  ],
  about: [
    {
      name: "Our Team",
      url: "/about-us",
      id: "about-intro"
    },
    {
      name: "Our Supporters",
      url: "/our-supporters",
      id: "about-supporters"
    }
  ]
};

function RelatedArticles(props) {
  const { subsection, selectedId } = props;
  const articleGroup = relatedArticles[subsection];

  return (
    <div className={article.relatedArticles}>
      {articleGroup
        ? articleGroup.map(({ name, id, url }) => (
            <Link
              to={url}
              className={classNames(
                article.relatedArticle,
                selectedId === id && article.selected
              )}
            >
              &raquo; <span>{name}</span>
            </Link>
          ))
        : null}
    </div>
  );
}

function ArticleHeader(props) {
  const { title, section } = props;

  return (
    <Section>
      <Heading className={article.heading}>{section || title}</Heading>
    </Section>
  );
}

export default function Article(props) {
  const { data } = props;
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { title, section, subsection, id } = frontmatter;

  return (
    <Layout hero={<ArticleHeader {...{ ...frontmatter }} />}>
      <SEO title={title} />

      <Section className={article.container}>
        <div className={article.inner}>
          <ArticleTabs section={section} subsection={subsection} />

          <RelatedArticles subsection={subsection} selectedId={id} />

          <Subheading>{section ? title : ""}</Subheading>

          <div className={article.text}>
            <div
              className={article.markdown}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>

          <div className={article.footer}>
            <Link to="/" className={components.primary}>
              Go back to the homepage
            </Link>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
        section
        subsection
        id
      }
    }
  }
`;
