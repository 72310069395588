import classNames from "classnames";

import React from "react";
import components from "../styles/components.module.css";

export default function Subheading(props) {
  const { className, children, ...componentProps } = props;

  return (
    <h2
      className={classNames(components.subheading, className)}
      {...componentProps}
    >
      {children}
    </h2>
  );
}
